import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import OverviewLayout from '../../containers/layout/overview-layout'
import Heading from '../../components/ui/heading'
import { Container, Col } from '../../components/ui/wrapper'
import { ContactCard } from '../../containers/layout/detail-layout'
import { Wrapper } from './contacts-list.style'
import { slugify } from '../../utils/utilFunctions'

const ContactsList = ({ data, pageContext, location }) => {
  const { contacts, allCategories } = pageContext
  const pageData = data.page.edges[0].node

  return (
    <OverviewLayout
      location={location}
      pageContext={pageContext}
      pageData={pageData}
    >
      <Container>
        {allCategories.map((category) => (
          <Wrapper key={`category-row-${slugify(category.title)}`}>
            <Col md={12}>
              <Heading as="h3" mt="40px" mb="25px">
                {category.title}
              </Heading>
            </Col>

            {contacts
              .filter(
                (contact) =>
                  contact.role.filter((role) => role.title === category.title)
                    .length,
              )
              .map((contact) => (
                <ContactCard key={contact.id} {...contact} />
              ))}
          </Wrapper>
        ))}
      </Container>
    </OverviewLayout>
  )
}

export const query = graphql`
  query ContactsListQuery {
    page: allContentfulPagina(
      filter: { id: { eq: "a3504d6d-9832-5d95-b4aa-f0b0cf7775a9" } }
    ) {
      edges {
        node {
          headerTitel
          headerSubtitel
          headerButtonTekst
          headerButtonLink
          headerImage {
            file {
              url
              fileName
              contentType
            }
            fluid(maxWidth: 900) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
        }
      }
    }
  }
`

ContactsList.propTypes = {
  headingStyle: PropTypes.object,
}

export default ContactsList
